<template>
  <div>
    <div
      class="modal fade"
      id="modalOpen"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <h2 class="fonts">
              {{
                !day
                  ? "Existe um caixa aberto do dia anterior, para prosseguir feche-o."
                  : "Já existe um caixa aberto em operação, deseja encerra-lo ou prosseguir com suas vendas?"
              }}
            </h2>

            <div class="div">
              <button type="button" class="btn continue" @click="closeCaixa">
                Fechar o caixa ativo e iniciar um novo
              </button>
              <button
                type="button"
                class="btn default"
                @click="dashboard"
                v-if="day"
              >
                Continuar minhas vendas
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["verify", "idCaixa", "day"],
  methods: {
    closeCaixa() {
      $("#modalOpen").modal("hide");

      this.$store.commit("SET_CAIXAID", this.idCaixa);
      this.$store.commit("SET_VALIDATION", true);
      this.$nextTick(() => {
        this.$router.push("/logout");
      });
    },
    dashboard() {
      $("#modalOpen").modal("hide");

      this.$notify({
        group: "erros",
        type: "sucess",
        text: `<i class="icon ion-close-circled"></i>Seja bem vindo(a) ${this.$store.getters.getUser.displayName}`
      });

      this.$store.commit("SET_CAIXAID", this.idCaixa);
      this.$store.commit("SET_VALIDATION", true);
      this.$nextTick(() => {
        this.$router.push("/");
      });
    },
    teste(){
      
    }
  },
  watch: {
    verify: function(value) {
      if (value) {
        $("#modalOpen").modal({ backdrop: "static" });
        if(value === true && $(window).width() < 768){
          let teste = document.querySelector('.modal-backdrop')
          teste.style.position = 'relative'
          $("#modalOpen").modal("hide");
          this.$router.push('/openCashRegister')
        }
      }
    }
  }
};
</script>

<style scoped>
.div {
  display: flex;
}

.fonts {
  color: #868686 !important;
  padding: 20px;
  font-size: 15px;
  text-align: center;
}

.btn {
  width: 45%;
  margin: 0 auto;
  border-radius: 7px;
  font-weight: 500;
  color: #ffff;
  -webkit-box-shadow: 0px 3px 6px 0 rgb(68 68 68 / 30%);
  box-shadow: 0px 3px 6px 0 rgb(68 68 68 / 30%);
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
  font-size: 14px;
}

.btn.default {
  border: 1px solid #2474b2;
  background-color: #2474b2;
}

.btn.continue {
  /* background: linear-gradient(to bottom, #f28236 0%, #e0a638 100%); */
  background: #e0a638;
}
</style>
